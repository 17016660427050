import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';


export default function SetPage_hook(){

    const [cookies] = useCookies(['uuid']);

    // 쿠키에서 UUID를 이용하여 사용자 정보 가져오기
    const[uid,setUID] = useState(0);
    const[nickname,setNickname] = useState('');

    useEffect(() => {
      if (!cookies.uuid) {
        alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
        window.location.href = `/Login_ats`;
        return;
      } else {
        const uuid = cookies.uuid;
        fetchUserInfo(uuid);
      }
    }, []);

    useEffect(() => {
      console.log('클라이언트 uuid', cookies.uuid);
    }, [cookies.uuid]);
  
    const fetchUserInfo = (uuid) => {
      fetch(`/SetPage/user?uuid=${uuid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          const user = data[0];
          setUID(user.uid);
          setNickname(user.nickname);
          fetchLists(user.uid);
        })
        .catch(error => {
          alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
          window.location.href = `/Login_ats`;
          return;
        });
    };
    
    console.log(uid);

    // selectedlevel, entry, waiting list 받아오기
    const fetchLists = (uid) => {
      fetch(`/startcheck/searchlevel?uid=${uid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          if(data[0].super_user === "8"){
            setLevelCheck(data[0].super_user);  
          }else{
            setLevelCheck(data[0].selectedLevel);
          }
          console.log(setlevel);
        })
        .catch(error => {
          console.error('Entry List 가져오기 오류:', error);
          alert(error);
        });

      fetch(`/startcheck_hook/list_entry?uid=${uid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          updateList(data, e_items, setE_Items);
        })
        .catch(error => {
          console.error('Entry List 가져오기 오류:', error);
          alert(error);
        });
  
      fetch(`/startcheck_hook/list_wait?uid=${uid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          updateList(data, w_items, setW_Items);
        })
        .catch(error => {
          console.error('Waiting List 가져오기 오류:', error);
          alert(error);
        });
    };
  
    const updateList = (data, list, setList) => {
      for (let i = 0; i < data.length; i++) {
        if (!isItemInList(data[i].symbol, list)) {
          setList(prevList => [...prevList, data[i].symbol]);
        }
      }
    };

    const isItemInList = (item, list) => {
      return list.includes(item);
    };

    const[symbol,setSymbol] = useState('default');
    const[leverage,setLeverage] = useState(10);
    const[firstusdt,setFirstUsdt] = useState(5);


    const[halfusdt, setHalfUsdt] = useState(2);
    const[halfratio, setHalfRatio] = useState(0.4);
    const[halfgrowper, setHalfGrowPer] = useState(100);
    const[halfnum, setHalfNum] = useState(3);


    const[objbenefit, setObjBenefit] = useState(0.8);
    const[objloss, setObjLoss] = useState(1.0);


    const[leverageValid,setLeverageValid] = useState(true);
    const[firstusdtValid,setFirstUsdtValid] = useState(true);


    const[halfusdtValid, setHalfUsdtValid] = useState(true);
    const[halfratioValid, setHalfRatioValid] = useState(true);
    const[halfgrowperValid, setHalfGrowPerValid] = useState(true);
    const[halfnumValid, setHalfNumValid] = useState(true);


    const[objbenefitValid, setObjBenefitValid] = useState(true);
    const[objlossValid, setObjLossValid] = useState(true);


    const[setlevel, setLevelCheck] = useState(0);

    const[notAllow, setNotAllow] = useState(true);

    const[e_items, setE_Items] = useState([]);
    const[w_items, setW_Items] = useState([]);

    
    const[trade_start,setTradeStart] = useState(false);

    
    const[benefit_stop,setBenefit_stop] = useState(false);
    const[benefit_cancel,setBenefit_cancel] = useState(false);

    const[currentCoin_stop,setCurrentCoin_stop] = useState(false);
    const[allCoin_stop,setAllCoin_stop] = useState(false);

    const[end,setEnd] = useState(false);



    const handleLeverage = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid(true);
          } 
        else {
          setLeverageValid(false);
          }
        };

    const handleFirstUsdt = (e) => {
      const newFirstusdt = e.target.value.trim();
      setFirstUsdt(newFirstusdt);
        const regex = 
        /^\d*$/;
        if (regex.test(newFirstusdt)) {
          setFirstUsdtValid(true);
          } 
        else {
          setFirstUsdtValid(false);
          }
        };



    const handleHalfUsdt= (e) => {
      const newHalfusdt = e.target.value.trim();
      setHalfUsdt(newHalfusdt);
        const regex = 
        /^\d*$/;
        if (regex.test(newHalfusdt)) {
          setHalfUsdtValid(true);
          } 
        else {
          setHalfUsdtValid(false);
          }
        };

    const handleHalfRatio = (e) => {
      const newHalfratio = e.target.value.trim();
      setHalfRatio(newHalfratio);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newHalfratio)) {
          setHalfRatioValid(true);
          } 
        else {
          setHalfRatioValid(false);
          }
        };

    const handleHalfGrowPer = (e) => {
      const newHalfgrowper = e.target.value.trim();
      setHalfGrowPer(newHalfgrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newHalfgrowper)) {
          setHalfGrowPerValid(true);
          } 
        else {
          setHalfGrowPerValid(false);
          }
        };

    const handleHalfNum = (e) => {
      const newHalfnem = e.target.value.trim();
      setHalfNum(newHalfnem);
        const regex = 
        /^\d*$/;
        if (regex.test(newHalfnem)) {
          setHalfNumValid(true);
          } 
        else {
          setHalfNumValid(false);
          }
        };
        


    const handleObjBenefit = (e) => {
      const newObjbenefit = e.target.value.trim();
      setObjBenefit(newObjbenefit);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newObjbenefit)) {
          setObjBenefitValid(true);
          } 
        else {
          setObjBenefitValid(false);
          }
        };
    
    const handleObjLoss = (e) => {
      const newObjloss = e.target.value.trim();
      setObjLoss(newObjloss);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newObjloss)) {
          setObjLossValid(true);
          } 
        else {
          setObjLossValid(false);
          }
        };

     
    const addListItem = (newItem, listSetter) => {
      listSetter(prevList => [...prevList, newItem]);
    };

    const removeListItem = (itemName, listSetter) => {
      listSetter(prevList => prevList.filter(item => item !== itemName));
    };
    
    const onClickCoin = (coin) => {
      setSymbol(coin);
      onClickSearch(coin);
    }

    useEffect(() => {

      if(leverageValid && firstusdtValid && halfusdtValid && halfratioValid && halfgrowperValid &&
        halfnumValid && objbenefitValid && objlossValid) {
        setNotAllow(false);
        return;
      }
      setNotAllow(true);
    }, [leverageValid, firstusdtValid, halfusdtValid, halfratioValid, halfgrowperValid,
      halfnumValid, objbenefitValid, objlossValid]);

    useEffect(() => {
        console.log(symbol);
    },[symbol]);

    const onClickSearch = (symbol) => {
      let urlName
      const str = symbol;
      console.log(str);
      const uidurl = uid;
      if(str === 'default'){
        urlName = 'default'
      }else{
        const index = str.indexOf('/');
        urlName = str.substring(0,index);
      }  
      fetch(`/setdata_hook/${uidurl}/${urlName}/${setlevel}`)
      .then((response) => {
        if(!response.ok) {
          throw new Error('400 아니면 500 에러남');
        }
        return response.json()
      })
      .then((result) => {
        console.log('db에서 받아온 데이터',result);
        setLeverage(result[0].leverage_info);
        setFirstUsdt(result[0].first_entry_usdt);
        setHalfUsdt(result[0].half_entry_usdt)
        setHalfRatio(result[0].half_ratio);
        setHalfGrowPer(result[0].half_grow_per);
        setHalfNum(result[0].half_num);

        setObjBenefit(result[0].magin);
        setObjLoss(result[0].obj_loss_ratio);

        setTradeStart(result[0].trade_start);
        setBenefit_stop(result[0].current_symbol_stop);


        const e_checklist = isItemInList(result[0].symbol, e_items);
        const w_checklist = isItemInList(result[0].symbol, w_items);
        
        if(str !== 'default'){
          result.forEach(item => {
            console.log('item',item)
            if ((item.trade_start === 1 || item.trade_start === '1') && e_checklist === false) {
              addListItem(item.symbol, setE_Items);
            }
            if ((item.trade_start === 0 || item.trade_start === '0') && e_checklist === true) {
              removeListItem(item.symbol, setE_Items);
            }
          
            // Waiting coin 리스트 삽입 및 삭제
            if ((item.current_symbol_stop === 1 || item.current_symbol_stop === '1') && w_checklist === false) {
              addListItem(item.symbol, setW_Items);
            }
            if ((item.current_symbol_stop === 0 || item.current_symbol_stop === '0') && w_checklist === true) {
              removeListItem(item.symbol, setW_Items);
            }
          });
        };

        console.log('e_items',e_items);
        console.log('e_checklist',e_checklist);

      })
      .catch((error) => {
        console.log('에러남',error);
      })
    }

    const onClickStart = () => {
      if (symbol === "default") {
        alert('코인을 먼저 선택하세요!');
        return; // Stop execution if the condition is met
      }
      
      if (symbol === "BTC/USDT:USDT" && firstusdt < 7) {
        alert('BTC/USDT:USDT는 최초 진입금액은 최소 7 USDT 이상이어야 합니다.');
        return; // Stop execution if the condition is met
      }

      if (symbol === "ETH/USDT:USDT" && firstusdt < 4) {
        alert('ETH/USDT:USDT는 최초 진입금액은 최소 4 USDT 이상이어야 합니다.');
        return; // Stop execution if the condition is met
      }

      if (symbol === "ETC/USDT:USDT" && firstusdt < 3) {
        alert('ETC/USDT:USDT는 최초 진입금액은 최소 3 USDT 이상이어야 합니다..');
        return; // Stop execution if the condition is met
      }

      if (symbol === "LINK/USDT:USDT" && firstusdt < 2) {
        alert('LINK/USDT:USDT는 최초 진입금액은 최소 2 USDT 이상이어야 합니다..');
        return; // Stop execution if the condition is met
      }

      if (symbol === "SOL/USDT:USDT" && firstusdt < 2) {
        alert('SOL/USDT:USDT는 최초 진입금액은 최소 2 USDT 이상이어야 합니다..');
        return; // Stop execution if the condition is met
      }

      const confirmed = window.confirm('Are you sure you want to start this coin start trading?');

      if (confirmed) {
        fetch('/start_hook', {
          method : 'POST',
          headers: {
            'Content-Type' : 'application/json',
          },
          body: JSON.stringify({
            uid,
            symbol,
            leverage,
            firstusdt,
            halfusdt,
            halfratio,
            halfgrowper,
            halfnum,
            objbenefit,
            objloss,
            trade_start
            }),
         });
       };
      onClickSearch(symbol);
    };

    // 익절 후 정리
    const onClickBenefit_stop = (symbol) => {
      const confirmed = window.confirm('Do you want to clean up the selected coin after profiting?');

      if (confirmed) {
        setBenefit_stop(true);
        let urlName
        const str = symbol;
        console.log(str);
        const uidurl = uid;
        if(str === 'default'){
          urlName = 'default'
        }else{
          const index = str.indexOf('/');
          urlName = str.substring(0,index);
        }
        fetch(`/setdata_benefit_hook/${uidurl}/${urlName}/${setlevel}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('benefit에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
          })
        };
      onClickSearch(symbol);
    }

    // 익절 후 정리 취소 
    const onClickBenefit_cancel = (symbol) => {
      const confirmed = window.confirm('Do you want to cancel clearing after profiting the selected coins?');

      if (confirmed) {
        setBenefit_cancel(true);
        let urlName
        const str = symbol;
        console.log(str);
        const uidurl = uid;
        if(str === 'default'){
          urlName = 'default'
        }else{
          const index = str.indexOf('/');
          urlName = str.substring(0,index);
        }
        fetch(`/setdata_benefit_cancel_hook/${uidurl}/${urlName}/${setlevel}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('benefit_cancel에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
        })
      };
      onClickSearch(symbol);
    }

    // 현재 코인 정리 
    const onClickCurrentCoin_stop = (symbol) => {
      const confirmed = window.confirm('Would you like to liquidate the selected coins right away?');

      if (confirmed) {
        setCurrentCoin_stop(true);
        const str = symbol;
        console.log(str);
        const uidurl = uid;
        const index = str.indexOf('/');
        const urlName = str.substring(0,index);
        fetch(`/setdata_current_coin_clear_hook/${uidurl}/${urlName}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('current_stop에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
        })
      };
      onClickSearch(symbol);
    }

    //진입중인 모든 코인 정리
    const onClickAllCoin_stop = () => {
      const confirmed = window.confirm('Do you really want to liquidate all coins that are currently entering the market?');

      if (confirmed) {
        setAllCoin_stop(true);
        const str = symbol;
        console.log(str);
        const uidurl = uid;
        const index = str.indexOf('/');
        const urlName = str.substring(0,index);
        fetch(`/setdata_all_coin_clear_hook/${uidurl}/${urlName}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('current_stop에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
        })
      };
      onClickSearch(symbol);
    }

    //로그아웃
    const onClickEnd = () => {
        const uuid = cookies.uuid; // cookies에서 uuid를 얻어옴
        if (!uuid) {
          alert('로그인 정보가 존재하지 않습니다.');
          return;
        }
        // 로그아웃 요청을 서버로 보냅니다.
        fetch('/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId: uuid })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('로그아웃 요청 실패');
          }
          console.log('로그아웃 성공');
          alert('Logout is successful.');
          window.location.href = '/';
        })
        .catch(error => {
          console.error('로그아웃 요청 중 오류 발생:', error);
        });
      // UUID를 서버로 보내는 예시 코드

    }


    return (

        <div className="page_sp">
          <div className="title_top_sp">
            <div></div>
            <div className="titleWrap_sp">Just Jump trade set (Hook version)</div>
            <div className="inputTitle_uid">
                <span>UID : {uid}</span>
                <span>{nickname}님 환영합니다</span>
            </div>
          </div>

            <div className="container">
              <div className="column">

            <div className="button_benefit">
              <select className="usdt_select" value = {symbol} onChange={(e) => {setSymbol(e.target.value); onClickSearch(e.target.value);}}>
                <option value="default">USDT Select</option>
                <option value="BTC/USDT:USDT">BTC/USDT</option>
                <option value="BOME/USDT:USDT">BOME/USDT</option>
                <option value="BCH/USDT:USDT">BCH/USDT</option>
                <option value="DOGE/USDT:USDT">DOGE/USDT</option>
                <option value="ENA/USDT:USDT">ENA/USDT</option>
                <option value="EOS/USDT:USDT">EOS/USDT</option>
                <option value="HBAR/USDT:USDT">HBAR/USDT</option>
                <option value="LTC/USDT:USDT">LTC/USDT</option>
                <option value="LINK/USDT:USDT">LINK/USDT</option>
                <option value="PEPE/USDT:USDT">PEPE/USDT</option>
                <option value="PEOPLE/USDT:USDT">PEOPLE/USDT</option>
                <option value="REZ/USDT:USDT">REZ/USDT</option>
                <option value="SOL/USDT:USDT">SOL/USDT</option>
                {/* <option value="SUI/USDT:USDT">SUI/USDT</option> */}
                <option value="TNSR/USDT:USDT">TNSR/USDT</option>
                <option value="WIF/USDT:USDT">WIF/USDT</option>
                <option value="XRP/USDT:USDT">XRP/USDT</option>
              </select>
            <button onClick={() => onClickSearch(symbol)} disabled={!symbol} className="button_benefit_c">
                Search
            </button>

            </div>
            <div className="contentWrap_sp">
                <div className="leverageWrap_sp">레버리지 셋팅
                <div style={{marginLeft:"auto"}}>X</div>
                  <div className="lvWrap_sp">
                      <input 
                      type='number'
                      className="input_sp"
                      placeholder="Enter only integers"
                      value={leverage}
                      disabled={setlevel <= 7}
                      onChange={handleLeverage} />
                  </div>
                </div>
                <div className="errorMessageWrap_sp">
                    {!leverageValid && leverage.length > 0 && (
                        <div>Please enter only integers.</div>
                )}
            </div>

            <div style={{ marginTop: "10px"}} className="inputTitle_sp">진입USDT
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only integers"
                    value={firstusdt}
                    disabled={setlevel <= 7}
                    onChange={handleFirstUsdt} />
                </div>
                </div>
                <div className="errorMessageWrap_sp">
                    {!firstusdtValid && firstusdt.length > 0 && (
                        <div>Please enter only integers.</div>
                    )}
            </div>

            <div style={{ marginTop: "10px"}} className="inputTitle_sp">익절(%)
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only decimal format"
                    value={objbenefit}
                    disabled={setlevel <= 7}
                    onChange={handleObjBenefit} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!objbenefitValid && objbenefit.length > 0 && (
                        <div>Please enter in decimal format only.</div>
                    )}
              </div>

              <div style={{ marginTop: "10px"}} className="inputTitle_sp">손절(%)
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only decimal format"
                    value={objloss}
                    disabled={setlevel <= 7}
                    onChange={handleObjLoss} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!objlossValid && objloss.length > 0 && (
                        <div>Please enter in decimal format only.</div>
                    )}
              </div>




            <div className="line"></div>
            
            <div style={{ marginTop: "10px", wordBreak: "break-all"}} className="inputTitle_sp">web line 진입금액
              <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only integers"
                    value={halfusdt}
                    disabled={setlevel <= 7}
                    onChange={handleHalfUsdt} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!halfusdtValid && halfusdt.length > 0 && (
                        <div>Please enter only integers.</div>
                    )}
            </div>

            <div style={{ marginTop: "10px"}} className="inputTitle_sp">web  line 진입간격(%)
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only decimal format"
                    value={halfratio}
                    disabled={setlevel <= 7}
                    onChange={handleHalfRatio} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!halfratioValid && halfratio.length > 0 && (
                       <div>Please enter in decimal format only.</div>
                        )}
            </div>

            <div style={{ marginTop: "10px"}} className="inputTitle_sp">web  line 증가율(%)
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only decimal format"
                    value={halfgrowper}
                    disabled={setlevel <= 7}
                    onChange={handleHalfGrowPer} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!halfgrowperValid && halfgrowper.length > 0 && (
                       <div>Please enter in decimal format only.</div>
                        )}
            </div>

            <div style={{ marginTop: "10px"}} className="inputTitle_sp">web  line 진입횟수
                <div className="inputWrap_sp">
                    <input 
                    type='number'
                    className="input_sp"
                    placeholder="Enter only integers"
                    value={halfnum}
                    disabled={setlevel <= 7}
                    onChange={handleHalfNum} />
                </div>
              </div>
                <div className="errorMessageWrap_sp">
                    {!halfnumValid && halfnum.length > 0 && (
                        <div>Please enter only integers.</div>
                    )}
            </div>


            <div className="line"></div>


            <div className="button_benefit">
                <button onClick={() => onClickStart()} disabled={trade_start === 1 ||  benefit_stop === 1 || trade_start === '1' ||  benefit_stop === '1' || setlevel <= 7} className="bottomButton_sp">
                Trading start
                </button>
            </div>

            <div className="button_benefit">
                <button onClick={() => onClickBenefit_stop(symbol)} disabled={benefit_stop === 1 || trade_start === 0 || benefit_stop === '1' || trade_start === '0'} className="button_benefit_c">
                코인익절 후 정리
                </button>
                <button onClick={() => onClickBenefit_cancel(symbol)} disabled={benefit_stop === 0 || benefit_stop === '0'} className="button_benefit_c">
                취 소
                </button>
            </div>

            <div className="button_benefit">
                <button onClick={() => onClickCurrentCoin_stop(symbol)} disabled={(symbol === 'default' && trade_start === 0 &&  benefit_stop === 0) || (symbol === 'default' && trade_start === '0' &&  benefit_stop === '0') || setlevel <= 7} className="bottomButton_sp">
                현재 코인 정리
                </button>
            </div>

            <div className="button_benefit">
                <button onClick={() => onClickEnd()} disabled={notAllow} className="bottomButton_sp">
                로그 아웃
                </button>
             </div>
           </div>
        </div>



          <div className="column">
              <div className="list_box_title">Entry coins</div>
              <ul className="list_box">
                {e_items.map((item, index) => (
                  <li key={index} onClick={() => onClickCoin(item)}>{item}</li>
                ))}
              </ul>
            </div>
          <div className="column">
            <div className="list_box_title">Waiting coins</div>
              <ul className="list_box">
                {w_items.map((item, index) => (
                    <li key={index} onClick={() => onClickCoin(item)}>{item}</li>
                  ))}
              </ul>
            </div>
          
          </div>
    </div>
    );
}