import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';


export default function AdminLogin(){
  
    const[id,setId] = useState('');
    const[pw,setPw] = useState('');

    const [cookies, setCookie] = useCookies(['uuid']);

    
    const handleid = (e) => {
        setId(e.target.value);
        };
    const handlePw = (e) => {
          setPw(e.target.value);
        };
    
    // useEffect(() => {
    //     if(emailValid && pwValid) {
    //         setNotAllow(false);
    //         return;
    //     }
    //     setNotAllow(true);
    //     }, [emailValid, pwValid]);
    const onClickConfirmButton = () => {
      const loginData = {
        id: id,
        pw: pw
      };
      
      // 서버에 POST 요청을 보냄
      fetch('admin/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginData)
      })
      .then(response => {
        console.log(response);
        if (!response.ok) {
          throw new Error('서버 응답 실패');
        }
        return response.text();
      })
      .then(data => {
          const mg_data = JSON.parse(data)
          console.log(data);
          console.log(mg_data.message);
          if(mg_data.message === undefined){
            let redirectUrl
            let newUUID
            if (mg_data[0].uuid === undefined){
              newUUID = mg_data.uuid;
            } else {
              newUUID = mg_data[0].uuid;
            }
            alert('로그인에 성공했습니다.');
            setCookie('uuid', newUUID, { path: '/' });
            console.log(newUUID);
            // 로그인 성공 후 리다이렉트할 URL
            redirectUrl = `AdminPage`;
              // 페이지를 리다이렉트
            window.location.href = redirectUrl;
          } else {
            alert(mg_data.message)
          }
       })
      .catch(error => {
        console.error('오류 발생:', error);
        alert(error);
      });
      
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        onClickConfirmButton();
      }
    };

    return(
        <div className="page">
            <div className="titleWrap">
                슈퍼 관리자 로그인 페이지입니다.
            </div>

            <div className="contentWrap">
                <div className="inputTitle">ADMIN ID</div>
                <div className="inputWrap">
                    <input 
                    type='text'
                    className="input"
                    value={id}
                    onChange={handleid}
                    onKeyDown={handleKeyDown}
                     />
                </div>

            <div style={{ marginTop: "26px"}} className="inputTitle">ADMIN PASSWORD</div>
                <div className="inputWrap">
                    <input 
                    type='password'
                    className="input"
                    value={pw}
                    onChange={handlePw}
                    onKeyDown={handleKeyDown}
                     />
                </div>
            </div>

            <div>
                <button onClick={onClickConfirmButton} className="bottomButton">
                    로그인
                </button>
            </div>
            <div className="signuplink">
                <a href="/">메인 페이지</a>
            </div>
        </div>
    );
}