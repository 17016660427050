import React, { useEffect } from 'react';

const Main = () => {
    useEffect(() => {
        window.location.href = 'https://jj-traders.imweb.me';
    }, []);
};
// import { Link } from 'react-router-dom';

// const Main = () => {
//     return (
//         <>
//             <h3>안녕하세요. 메인페이지 입니다.</h3>
//             <ul>
//                 <li className="list_sub"><Link to="/Login_ats">Log in</Link></li>
//                 <li className="list_sub"><Link to="/CheckboxPage">Checkbox page</Link></li>
//                 <li className="list_sub"><Link to="/Signup_ats">Sign up</Link></li>
//                 <li className="list_sub"><Link to="/SelectPage">Select Page</Link></li>
//                 <li className="list_sub"><Link to="/SetPage">Set Page</Link></li>
//                 <li className="list_sub"><Link to="/SetPage_hook">Set Page hook</Link></li>
//                 {/* <li className="list_sub"><Link to="/AdminLogin">Admin Login</Link></li> */}
//                 {/* <li className="list_sub"><Link to="/Withdrawal"> Membership withdrawal</Link></li> */}
//             </ul>
//         </>
//     );
// };

export default Main;
