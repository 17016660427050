import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';


export default function CheckboxPage() {

  const [cookies] = useCookies(['uuid']);

  console.log(cookies.uuid)

  // 쿠키에서 UUID를 이용하여 사용자 정보 가져오기
  const[uid,setUID] = useState(0);
  const[nickname,setNickname] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedtotalusdt, setSelectedTotalUsdt] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async (uuid) => {
      try {
        const response = await fetch(`/SetPage/user?uuid=${uuid}`);
        if (!response.ok) {
          throw new Error('서버 응답 실패');
        }
        const data = await response.json();
        const user = data[0];
        setUID(user.uid);
        setNickname(user.nickname);
        fetchLists(user.uid);
      } catch (error) {
        alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
        window.location.href = `/Login`;
      }
    };

    if (!cookies.uuid) {
      alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
      window.location.href = `/Login`;
    } else {
      fetchUserInfo(cookies.uuid);
    }
  }, [cookies.uuid]);

  // fetchLists 함수
  const fetchLists = async (uid) => {
    try {
      const response = await fetch(`/startcheck/basic_total_usdt?uid=${uid}`);
      if (!response.ok) {
        throw new Error('서버 응답 실패');
      }
      const data = await response.json();
      let super_user = data[0]?.super_user || data.super_user;
      if (super_user === '8') {
        setSelectedLevel(8);
        setSelectedTotalUsdt(8);
      } else {
        setSelectedLevel(data[0]?.basic_total_usdt);
        setSelectedTotalUsdt(data[0]?.selectedtotalusdt);
      }
    } catch (error) {
      console.error('Entry List 가져오기 오류:', error);
      alert(error);
    }
  };

  useEffect(() => {
      fetchLists(uid);
  }, [uid]);

  // 라디오 버튼의 변경 핸들러
  const handleRadioChange = (e) => {
    setSelectedLevel(e.target.value);
  };

  // 확인 버튼 클릭 핸들러
  const handleConfirm = () => {
    alert(`Selected Level: ${selectedLevel} 의 셋팅값으로 매매를 시작합니다.`);
    fetch(`/startcheck/levelcheck?uid=${uid}&selectedLevel=${selectedLevel}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          console.log(data)
          // 레벨값 계산 후 리다이렉트할 URL
          const redirectUrl = `/SetPage`;
          // 페이지를 리다이렉트
          window.location.href = redirectUrl;
        })
        .catch(error => {
          console.error('Entry List 가져오기 오류:', error);
          alert(error);
        });
      };

  return (
  <>
    <div className="container_check">
      <h2>Select User Level</h2>
      <div className="radio-group">
        <label className="radio-label">
          <input
            className="radio-input"
            type="radio"
            name="userLevel"
            value="1"
            checked={selectedtotalusdt && selectedtotalusdt >= 1 && selectedLevel === "1"}
            onChange={handleRadioChange}
            disabled={selectedtotalusdt < 1}
          />{" "}
          1300이상
        </label>
        <label className="radio-label">
          <input
            className="radio-input"
            type="radio"
            name="userLevel"
            value="2"
            checked={selectedtotalusdt && selectedtotalusdt >= 2 && selectedLevel === "2"}
            onChange={handleRadioChange}
            disabled={selectedtotalusdt < 2}
          />{" "}
          2200이상
        </label>
        <label className="radio-label">
          <input
            className="radio-input"
            type="radio"
            name="userLevel"
            value="3"
            checked={selectedtotalusdt && selectedtotalusdt >= 3 && selectedLevel === "3"}
            onChange={handleRadioChange}
            disabled={selectedtotalusdt < 3}
          />{" "}
          3500이상
        </label>
        <label className="radio-label">
          <input
            className="radio-input"
            type="radio"
            name="userLevel"
            value="4"
            checked={selectedtotalusdt && selectedtotalusdt >= 4 && selectedLevel === "4"}
            onChange={handleRadioChange}
            disabled={selectedtotalusdt < 4}
          />{" "}
          5000이상
        </label>
        <label className="radio-label">
          <input
            className="radio-input"
            type="radio"
            name="userLevel"
            value="5"
            checked={selectedtotalusdt && selectedtotalusdt >= 5 && selectedLevel === "5"}
            onChange={handleRadioChange}
            disabled={selectedtotalusdt < 5}
          />{" "}
          7000이상
        </label>
        <label className="radio-label">
          <input
            className="radio-input"
            type="radio"
            name="userLevel"
            value="6"
            checked={selectedtotalusdt && selectedtotalusdt >= 6 && selectedLevel === "6"}
            onChange={handleRadioChange}
            disabled={selectedtotalusdt < 6}
          />{" "}
          10000이상
        </label>
        <label className="radio-label">
          <input
            className="radio-input"
            type="radio"
            name="userLevel"
            value="7"
            checked={selectedtotalusdt && selectedtotalusdt >=7 && selectedLevel === "7"}
            onChange={handleRadioChange}
            disabled={selectedtotalusdt < 7}
          />{" "}
          15000이상
        </label>
        <label className="radio-label">
          <input
            className="radio-input"
            type="radio"
            name="userLevel"
            value="8"
            checked={selectedtotalusdt && selectedtotalusdt >= 8 && selectedLevel === "8"}
            onChange={handleRadioChange}
            disabled={selectedtotalusdt < 8}
          />{" "}
          SUPER USER
        </label>
      </div>
      <div>
        <p>Selected Level: {selectedLevel}</p>
      </div>
      <div>
        <button className = 'bottomButton_sp' disabled={selectedLevel === 0} onClick={handleConfirm}>Confirm</button>
      </div>
    </div>
    <div className="user-info">
      <p>UID: {uid}</p>
      <p>Nickname: {nickname}</p>
  </div>
</>
  );
}
