import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';


export default function Login(){

    const[email,setEmail] = useState('');
    const[pw,setPw] = useState('');

    const [showMessage, setShowMessage] = useState(false);

    const[emailValid, setEmailValid] = useState(false);
    const[pwValid, setPwValid] = useState(false);
    const[notAllow, setNotAllow] = useState(true);

    const [cookies, setCookie] = useCookies(['uuid']);

    useEffect(() => {
      // 여기에 통신 요청이 완료되면 알림 메시지를 자동으로 사라지게 하는 로직을 추가하세요.
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // 5초 후에 알림 메시지를 숨김
      return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머 해제
    }, [showMessage]); 

    
    const handleEmail = (e) => {
      const newEmail = e.target.value.trim();
      setEmail(newEmail);
      const regex = 
      /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      if (regex.test(newEmail)) {
          setEmailValid(true);
        } 
      else {
          setEmailValid(false);
        }
      };
        
    const handlePw = (e) => {
      const newPw = e.target.value.trim();
      setPw(newPw);
        const regex =
          /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+])(?!.*[^a-zA-z0-9$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/;
        if (regex.test(newPw)) {
          setPwValid(true);
        } 
      else {
          setPwValid(false);
        }
      };
    
    useEffect(() => {
        if(emailValid && pwValid) {
          setNotAllow(false);
          return;
        }
        setNotAllow(true);
      }, [emailValid, pwValid]);

    const onClickConfirmButton = () => {
      const loginData = {
        email: email,
        password: pw
      };
      
      setShowMessage(true);

      // 서버에 POST 요청을 보냄
      fetch('/login/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginData)
      })
      .then(response => {
        console.log(response);
        if (!response.ok) {
          throw new Error('서버 응답 실패');
        }
        return response.text();
      })
      .then(data => {
          const mg_data = JSON.parse(data)
          setShowMessage(false);
          if(mg_data.message === undefined){
            let redirectUrl
            let newUUID
            let direct_setpage
            if (mg_data[0].uuid === undefined){
              newUUID = mg_data.uuid;
            } else {
              newUUID = mg_data[0].uuid;
            }
            setCookie('uuid', newUUID, { path: '/' });
            console.log(cookies);
            console.log(newUUID);

            if (mg_data[0].direct_setpage === undefined){
              direct_setpage = mg_data.direct_setpage;
            } else {
              direct_setpage = mg_data[0].direct_setpage;
            }
            console.log(direct_setpage);
            alert('로그인에 성공했습니다.');

            redirectUrl = `/SelectPage`;
            window.location.href = redirectUrl;

          } else {
            alert(mg_data.message)
          }
       })
      .catch(error => {
        console.error('오류 발생:', error);
        alert(error);
      }); 
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        onClickConfirmButton();
      }
    };

    return(
        <div className="page">
            <div className="titleWrap">
                Just Jump trading system
                <br/>
                Login page
                <br/>
                이메일과 비밀번호를
                <br/>
                입력해주세요
            </div>

            <div className="contentWrap">
                <div className="inputTitle">이메일 주소</div>
                <div className="inputWrap">
                    <input 
                    type='text'
                    className="input"
                    placeholder="test@gmail.com"
                    value={email}
                    onChange={handleEmail}
                    onKeyDown={handleKeyDown}
                    />
                </div>
                <div className="errorMessageWrap">
                    {!emailValid && email.length > 0 && (
                        <div>올바른 이메일을 입력해주세요.</div>
                )}
            </div>

            <div style={{ marginTop: "26px"}} className="inputTitle">비밀번호</div>
                <div className="inputWrap">
                    <input 
                    type='password'
                    className="input"
                    placeholder="영문(대문자 포함), 숫자, 특수문자 포함 8자 이상"
                    value={pw}
                    onChange={handlePw}
                    onKeyDown={handleKeyDown}
                    />
                </div>
                <div className="errorMessageWrap">
                    {!pwValid && pw.length > 0 && (
                        <div>영문, 숫자, 특수문자 포함 8자 이상 입력해주세요.</div>
                    )}
                </div>
            </div>

            {showMessage && (
              <div className="popupMessage">
                거래소와 통신 중입니다. 잠시만 기다리세요!
              </div>
            )}

            <div>
                <button onClick={onClickConfirmButton} disabled={notAllow} className="bottomButton">
                    확인
                </button>
            </div>
            <div className="signuplink">
                <a href="/">메인 페이지</a>
                <a href="/signup_ats">회원가입 페이지</a>
            </div>
        </div>
    );
}