import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';

export default function AdminPage() {
  const [users, setUsers] = useState([]);
  const [cookies] = useCookies(['uuid']);

  // 쿠키에서 UUID를 이용하여 사용자 정보 가져오기
  const[id,setID] = useState('');
  const[pw,setPW] = useState('');
  const[admin_possible,setAD_PS] = useState('');

  useEffect(() => {
    if (!cookies.uuid) {
      alert('로그인 정보가 존재하지 않습니다. 관리자 로그인 페이지로 이동합니다.');
      window.location.href = `/AdminLogin`;
      return;
    } else {
      const uuid = cookies.uuid;
      fetchUserInfo(uuid);
    }
  }, []);

  useEffect(() => {
    console.log('관리자 uuid', cookies.uuid);
  }, [cookies.uuid]);

  const fetchUserInfo = (uuid) => {
    fetch(`/SetPage/admin_user?uuid=${uuid}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('서버 응답 실패');
        }
        return response.json();
      })
      .then(data => {
        const user = data[0];
        setID(user.id);
        setPW(user.pw);
        setAD_PS(user.admin_possible);
      })
      .catch(error => {
        alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
        window.location.href = `/AdminLogin`;
        return;
      });
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`/admin/users`);
        if (!response.ok) {
          throw new Error('서버 응답 실패');
        }
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Waiting List 가져오기 오류:', error);
        alert(error);
      }
    };
  
    fetchUsers();
  }, []);
  
  // 사용자 상태값 변경 시마다 useEffect 호출
  useEffect(() => {
    // 상태값이 변경될 때 필요한 작업 수행
    console.log('Users 상태값이 변경되었습니다:', users);
  }, [users]);


  const handleApprove = async (uid, nickname) => {
    const confirmed = window.confirm(`UID : ${uid} / NICKNAME : ${nickname} 님을 사용승인 처리하시겠습니까?`);

    if (confirmed) {
        try {
          const response = await fetch('/admin/approve', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uid }),
          });
          if (!response.ok) {
            throw new Error('Failed to approve user');
          }
          const data = await response.json();
          // 해당 사용자의 정보만을 업데이트합니다.
          setUsers(prevUsers => prevUsers.map(user => {
            if (user.uid === uid) {
              return { ...user, login_possible: data[0].login_possible };
            }
            console.log(users)
            return user;
          }));
            alert(`UID : ${uid} / NICKNAME : ${nickname} 님의 사용승인이 완료 되었습니다.`);
            // 로그인 가능한 경우에 필요한 작업 수행
         } catch (error) {
          console.error('Error approving user:', error);
        }
      };
  };

  const handleRevoke = async (uid,nickname) => {
    const confirmed = window.confirm(`UID : ${uid} / NICKNAME : ${nickname} 님을 사용해제 처리하시겠습니까?`);

    if (confirmed) {
      try {
        const response = await fetch('/admin/revoke', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        });
        if (!response.ok) {
          throw new Error('Failed to revoke user');
        }

        const data = await response.json();
        // 해당 사용자의 정보만을 업데이트합니다.
        if (data.login_possible === 'revoke') {
          setUsers(prevUsers => prevUsers.filter(user => user.uid !== uid));
        } 
          alert(`UID : ${uid} / NICKNAME : ${nickname} 님의 사용 해제가 완료 되었습니다.`);
          return users;
        } catch (error) {
        console.error('Error revoking user:', error);
      }
    };
  };

  const handleGroup = async (uid, nickname) => {
    const confirmed = window.confirm(`UID : ${uid} / NICKNAME : ${nickname} 님을 별도 그룹으로 처리 하시겠습니까?`);

    if (confirmed) {
        try {
          const response = await fetch('/admin/group', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uid }),
          });
          if (!response.ok) {
            throw new Error('Failed to group user');
          }
          const data = await response.json();
          // 해당 사용자의 정보만을 업데이트합니다.
          setUsers(prevUsers => prevUsers.map(user => {
            if (user.uid === uid) {
              return { ...user, another_group: data[0].another_group };
            }
            console.log(data[0].another_group)
            return user;
          }));
          alert(`UID : ${uid} / NICKNAME : ${nickname} 님의 그룹 처리가 완료 되었습니다.`);
           // 로그인 가능한 경우에 필요한 작업 수행
         } catch (error) {
          console.error('Error approving user:', error);
        }
      };
  };

  const handleGroupRevoke = async (uid,nickname) => {
    const confirmed = window.confirm(`UID : ${uid} / NICKNAME : ${nickname} 님을 그룹해제 처리하시겠습니까?`);

    if (confirmed) {
      try {
        const response = await fetch('/admin/grouprevoke', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        });
        if (!response.ok) {
          throw new Error('Failed to revoke user');
        }

        const data = await response.json();
        // 해당 사용자의 정보만을 업데이트합니다.
        setUsers(prevUsers => prevUsers.map(user => {
          if (user.uid === uid) {
            return { ...user, another_group: data[0].another_group };
          }
          console.log(data[0].another_group)
          return user;
        }));
        alert(`UID : ${uid} / NICKNAME : ${nickname} 님의 그룹 해재 처리가 완료 되었습니다.`);
      } catch (error) {
        console.error('Error group approving user:', error);
    };
  };
};
  

  const handleSuperuserapproval = async (uid, nickname) => {
    const confirmed = window.confirm(`UID : ${uid} / NICKNAME : ${nickname} 님을 슈퍼 유저 승인처리하시겠습니까?`);
  
    if (confirmed) {
      try {
        const response = await fetch('/admin/superuserapproval', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        });
        if (!response.ok) {
          throw new Error('Failed to approve user');
        }
  
        const data = await response.json();
        // 해당 사용자의 정보만을 업데이트합니다.
        setUsers(prevUsers => prevUsers.map(user => {
          if (user.uid === uid) {
            return { ...user, super_user: data[0].super_user };
          }
          console.log(users)
          return user;
        }));
  
        alert(`UID : ${uid} / NICKNAME : ${nickname} 님의 슈퍼 유저 승인이 완료 되었습니다.`);
      } catch (error) {
        console.error('Error revoking user:', error);
      }
    };
  };
  

  const handleSuperuserrevoke = async (uid,nickname) => {
    const confirmed = window.confirm(`UID : ${uid} / NICKNAME : ${nickname} 님을 슈퍼유저 승인 해제 처리하시겠습니까?`);

    if (confirmed) {
      try {
        const response = await fetch('/admin/superuserrevoke', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        });
        if (!response.ok) {
          throw new Error('Failed to revoke user');
        }
        
        const data = await response.json();
        // 해당 사용자의 정보만을 업데이트합니다.
        setUsers(prevUsers => prevUsers.map(user => {
          if (user.uid === uid) {
            return { ...user, super_user: data[0].super_user };
          }
          console.log(users)
          return user;
        }));
        // 승인 해제가 성공적으로 처리되었을 때 필요한 작업 수행
        alert(`UID : ${uid} / NICKNAME : ${nickname} 님의 슈퍼 유저 승인 해체 처리 되었습니다.`);
      } catch (error) {
        console.error('Error revoking user:', error);
      }
    };
  };

  // 승인 및 슈퍼승인 여부에 따른 스타일을 정의하는 함수
const getApprovalStyle = (loginPossible, superUser) => {
  let style = {
    color: loginPossible === "1" || loginPossible === 1 ? "red" : "black",
  };

  if (superUser === "1") {
    style.fontWeight = "bold";
  }

  return style;
};

// 승인 및 슈퍼승인 여부에 따른 텍스트를 생성하는 함수
const getApprovalText = (loginPossible, another_group) => {
  let text = loginPossible === "1" || loginPossible === 1 ? "승인" : "승인대기중";

  if (another_group === "1" || another_group === 1) {
    text += " / 그룹";
  }

  return text;
};

//로그아웃
const handleLogout = () => {
  const uuid = cookies.uuid; // cookies에서 uuid를 얻어옴
  if (!uuid) {
    alert('로그인 정보가 존재하지 않습니다.');
    return;
  }
  // 로그아웃 요청을 서버로 보냅니다.
  fetch('/super_logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userId: uuid })
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('로그아웃 요청 실패');
    }
    console.log('로그아웃 성공');
    alert('Logout is successful.');
    window.location.href = '/adminlogin';
  })
  .catch(error => {
    console.error('로그아웃 요청 중 오류 발생:', error);
  });
// UUID를 서버로 보내는 예시 코드

}

  return (
    <div className="container_admin">
      <div className="header">
        <h2 className="title_admin">User Information (Super user page)</h2>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      <table className="user-table">
        <thead>
          <tr>
            <th>#</th>
            <th>UID</th>
            <th>Nickname</th>
            <th>승인여부</th>
            <th>Action</th>
            <th>Group</th>
          </tr>
        </thead>
        <tbody>
          {users.slice().reverse().map((user, index) => (
          <tr key={user.uid}>
            <td>{index + 1}</td>
            <td>{user.uid}</td>
            <td>{user.nickname}</td>
            <td style={getApprovalStyle(user.login_possible, user.another_group)}>{getApprovalText(user.login_possible, user.another_group)}</td>
            <td>
              <button className="action-button large-button" onClick={() => handleApprove(user.uid,user.nickname)}>Approve</button>
              <button className="action-button large-button" disabled={(admin_possible === '1' || admin_possible === 1)} onClick={() => handleRevoke(user.uid,user.nickname)}>Revoke</button>
            </td>
            <td>
              <button className="action-button large-button" disabled={(admin_possible === '1' || admin_possible === 1)} onClick={() => handleGroup(user.uid,user.nickname)}>Group Approve</button>
              <button className="action-button large-button" disabled={(admin_possible === '1' || admin_possible === 1)} onClick={() => handleGroupRevoke(user.uid,user.nickname)}>Group Revoke</button>
            </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
