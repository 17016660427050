import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import image8 from './images/8.jpg';
import image4 from './images/4.jpg';
import image3 from './images/3.jpg';
import image5 from './images/5.jpg';
import image6 from './images/6.jpg';

const Explanation = () => {
  const [cookies] = useCookies(['uuid']);

    // 쿠키에서 UUID를 이용하여 사용자 정보 가져오기
    const[uid,setUID] = useState(0);
    const[nickname,setNickname] = useState('');

    useEffect(() => {
      if (!cookies.uuid) {
        alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
        window.location.href = `/Login_ats`;
        return;
      } else {
        const uuid = cookies.uuid;
        fetchUserInfo(uuid);
      }
    }, []);

    useEffect(() => {
      console.log('클라이언트 uuid', cookies.uuid);
    }, [cookies.uuid]);
  
    const fetchUserInfo = (uuid) => {
      fetch(`/SetPage/user?uuid=${uuid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          const user = data[0];
          setUID(user.uid);
          setNickname(user.nickname);
        })
        .catch(error => {
          alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
          window.location.href = `/Login_ats`;
          return;
        });
    };

  const [showPage, setShowPage] = useState(true);

  const handleClose = () => {
    setShowPage(false);
    window.close(); // 브라우저의 페이지를 닫습니다. 단, 일부 환경에서는 정상적으로 동작하지 않을 수 있습니다.
  };

  if (!showPage) {
    return null; // 페이지를 닫으면 아무것도 표시되지 않도록 합니다.
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Just Jump trading system 자동매매프로그램 사용 설명</h2>
      <pre style={styles.text}>
      * 프로그램의 기본적인 작동방식은 <span style={styles.highlight_yellow}>목표수익률에 도달할때까지 무제한적으로 주문을 생성</span>합니다. {"\n"}
      * Inner line은 작은 파동에서 빨리 빠져나오기 위한 물타기 라인이고 Outer line은 큰 시장 변동에 대비하기 위한 물타기 라인입니다.{"\n"}
      * 프로그램은 총 3개의 단계로 나눠집니다.{"\n"}{"\n"}{"\n"}
      1단계는 포지션을 잡고 목표수익률에 맞춰서 클로즈포지션을 잡습니다.{"\n"}
      <span style={styles.highlight_yellow}>(포지션 진입 기준 - 과거의 데이터를 기준으로 여러가지를 종합적으로 분석합니다.)</span>{"\n"}
      2단계는 Inner line을 값을 셋팅합니다.{"\n"}
      3단계는 outer line을 값을 셋팅합니다.{"\n"}{"\n"}
      <img src={image8} alt="Image 8" style={styles.image} />
      <h2 style={styles.highlight_pink}>1단계 - 코인선택 및 기본셋팅 설정</h2>
      ① - 코인 선택 박스입니다{"\n"}{"\n"}
      ② - 선택한 코인의 셋팅값을 불러옵니다{"\n"}{"\n"}
      ③ - 레버리지값을 입력합니다. {"\n"}
      <span style={styles.highlight_yellow}>주의 - 1. 코인별 레버리지 최대값이 다르므로 확인하고 입력하세요  2. 정수만 입력하세요</span>{"\n"}{"\n"}
      ④ - 최초진입금액은 가장 처음 포지션을 잡을때의 금액입니다.{"\n"}
      <span style={styles.highlight_yellow}>주의 - 1. 코인별로 레벨별로 최소금액이 다르므로 확인하시고 입력해야 합니다.</span>{"\n"} 
      예시 BTC - 최소진입금액 7usdt / ETH - 4usdt / ETC - 3usdt / LINK - 2usdt (레버리지 10배 기준입니다){"\n"}{"\n"}
      ⑤ - 목표 수익률은 설정한 값에 클로즈라인을 생성합니다. (예시 - 0.3% / 이미지 참조){"\n"}
      추가설명 - <span style={styles.highlight_yellow}>클로즈라인은 현재 포지션을 항상 반영합니다.</span>현재 포지션 값이 변경되면 기존 클로즈라인은 취소되고 입력한 목표수익률에 맞춰 새로운 클로즈라인이 생성됩니다{"\n"}{"\n"}
      <img src={image4} alt="Image 4" style={styles.image} />
      <h2 style={styles.highlight_pink}>2단계 - Inner line 설정</h2>
      ⑥ - Inner line 진입금액은 안쪽 라인의 첫번째 진입금액 설정부분입니다. {"\n"}{"\n"}
      ⑦ - Inner line 진입간격은 안쪽 라인의 라인별 진입간격을 설정합니다. (예시 - 0.4% / 이미지 참조){"\n"}{"\n"}
      ⑧ - Inner line 증가율은 안쪽 라인의 라인별 진입증가율을 설정합니다. 라인이 추가로 생성될때마다 증가율만큼 진입금액이 증가합니다.(예시 - 100% / 이미지 참조){"\n"}{"\n"}
      ⑨ - Inner line 진입횟수는 안쪽 라인을 몇개나 생성할지 설정합니다. (예시 - 3 / 이미지 참조){"\n"}
      (추가설명 - Inner line 진입금액을 2usdt, 진입간격을 0.4%, 증가율을 100%, 진입횟수를 3으로 설정했다고 가정했을때 Inner line 첫번째 라인은 최초 진입한 라인에서 0.4%만큼 간격을 두고 2usdt로 주문을 생성하고 두번째 라인은 Inner line 첫번째 라인에서 0.4%만큼 간격을 두고 100%가 증가한 금액인 4usdt로 주문을 생성, 세번째 라인은 두번째 라인에서 0.4%만큼 간격을 두고 100%가 추가로 증가한 8usdt로 주문을 생성합니다. (이미지 참조)){"\n"}{"\n"}
      * <span style={styles.highlight_yellow}>Inner line은 셋팅된 값에 따라 한번만 생성됩니다.</span>{"\n"}
      * Inner line의 목적은 횡보장에서 효율적인 진입과 익절을 반복하게 하기 위함입니다.{"\n"}
      * 만약 Inner line이 필요없을 경우엔 Inner line 집입금액을 0 으로 설정하면 됩니다.(모든 Inner line 설정값을 0 으로 설정하는 것을 추천드립니다.) - Outer line의 효율적인 셋팅값만으로도 충분히 수익을 창출할 수 있습니다.{"\n"}{"\n"}
      <img src={image3} alt="Image 3" style={styles.image} />
      <h2 style={styles.highlight_pink}>3단계 - Outer line 설정</h2>
      ⑩ - Outer line 진입금액은 바깥쪽 라인의 첫번째 진입금액 설정부분입니다.{"\n"}{"\n"}
      ⑪ - Outer line 진입간격은 바깥쪽 라인의 라인별 진입간격을 설정합니다. (예시 - 5% / 이미지 참조){"\n"}{"\n"}
      ⑫ - Outer line 증가율은 바깥쪽 라인의 라인별 진입증가율을 설정합니다. 라인이 추가로 생성될때마다 증가율만큼 진입금액이 증가합니다.(예시 - 100% / 이미지 참조){"\n"}{"\n"}
      ⑬ - Outer line 진입횟수는 바깥쪽 라인을 몇개나 생성할지 설정합니다. (예시 - 3 / 이미지 참조){"\n"}{"\n"}
      (추가설명 - Outer line 진입금액을 20usdt, 진입간격을 5%, 증가율을 100%, 진입횟수를 3으로 설정했다고 가정했을때 Outer line 첫번째 라인은 최초 진입한 라인에서 5%만큼 간격을 두고 20usdt로 주문을 생성하고 두번째 라인은 Outer line 첫번째 라인에서 5%만큼 간격을 두고 100%가 증가한 금액인 40usdt로 주문을 생성, 세번째 라인은 두번째 라인에서 5%만큼 간격을 두고 100%가 추가로 증가한 80usdt로 주문을 생성합니다. (이미지 참조))
      * <span style={styles.highlight_yellow}>Outer line은 언제나 Outer line 진입횟수에 설정된 값을 유지합니다.</span> 만약 현재가격이 Outer line 첫번째 라인까지 도달해서 Outer line이 없어졌다면 마지막 Outer line에서 추가로 입력한 셋팅가격에 맞춰 추가로 생성됩니다.<span style={styles.highlight_yellow}>(시드가 있는 한 익절하고 나올때까지 지속적으로 생성)</span>{"\n"}
      * <span style={styles.highlight_red}>중요 주의 사항 - 여유분의 시드가 없으면 추가로 Outer line이 생성되지 않으므로 물타기가 진행되지 않기 때문에 전체시드의 50%이상을 여유분으로 남겨두고 셋팅하시길 바랍니다.</span>{"\n"}{"\n"}
      <img src={image5} alt="Image 5" style={styles.image} />
      <img src={image6} alt="Image 6" style={styles.image} />
      ⑭ - Trend 선택 박스(Trend / Countertrend){"\n"}
      Trend - 첫 진입시 기준에 맞춰서 추세에 따라 진입합니다. (예시 - 계산값이 롱이면 롱 포지션 진입){"\n"}
      Countertrend - 첫 진입시 기준에 맞춰서 역추세로 진입합니다. (예시 - 계산값이 롱이면 숏 포지션 진입){"\n"}{"\n"}
      ⑮ - Twoway 선택 박스 (Twoway / Long / Short){"\n"}
      Twoway - 첫 진입시 기준에 맞춰서 셋팅값에 따라 롱 / 숏 양쪽 방향 중 한쪽 방향으로 진입합니다.{"\n"}
      Long - 첫 진입시 기준에 맞춰서 셋팅값에 따라 롱일때만 롱으로 진입합니다. (숏 진입 안함){"\n"}
      Short - 첫 진입시 기준에 맞춰서 셋팅값에 따라 숏일때만 숏으로 진입합니다. (롱 진입 안함){"\n"}{"\n"}
      ⑯ - Trend strenth 선택 박스 (Trend strenth / Web hook){"\n"}
      Trend strenth - 첫 진입시 추세 강도에 맞춰서 진입합니다.{"\n"}
      Web hook - 첫 진입시 서버에서 보내주는 특정 기준에 맞춰서 진입합니다. (롱/숏도 같이 결정됩니다.(롱/숏 따로 지정 불가)){"\n"}{"\n"}
      ⑰ - Trading start 버튼은 선택한 코인을 셋팅값에 맞춰서 매매시작합니다.{"\n"}{"\n"}
      ⑱ - 코인익절 후 정리 버튼은 선택한 코인이 익절하면서 전체라인이 정리가 되면 더이상 추가로 진입하지 않고 리스트에서 삭제됩니다.(코인익절 후 정리 버튼을 누르면 선택한 코인을 Entry coins 리스트에서 Waiting coins 리스트로 이동시킵니다.){"\n"}
      * <span style={styles.highlight_yellow}>사용예시 - 시장 변동성이 너무 커서 코인들이 많이 물려 있고 보유 시드가 넉넉하지 않다고 판단될 때 이 코인익절 후 정리 버튼을 이용하여 익절 후 더이상 추가로 진입을 하지 않으므로 보유 시드를 확보할 수 있습니다.</span>{"\n"}{"\n"}
      ⑲ - 취소 버튼은 선택한 코인을 Waiting coins 리스트에서 Entry coins 리스트로 이동 시킵니다.{"\n"}{"\n"}
      ⑳ - 현재코인정리 버튼은 선택한 코인이 포지션을 잡고 있으면 포지션을 시장가로 정리하고 걸려 있는 모든 주문도 취소합니다. 선택한 코인은 Entry coins 또는 Waiting coins 리스트에서 삭제되고 더이상 추가로 진입하지 않습니다.<span style={styles.highlight_yellow}>(정리 할때까지 약 5~10초 소요)</span>{"\n"}{"\n"}
      ㉑ - 로그아웃 버튼은 로그아웃 기능을 제공합니다.{"\n"}{"\n"}
      ㉒ - Entry coins 리스트는 Trading start 버튼으로 진입중인 코인 리스트입니다.(이 리스트에 있는 코인들은 포지션 진입하고 익절하고를 지속적으로 반복합니다.(코인명을 클릭하면 코인선택 기능을 제공합니다.)){"\n"}{"\n"}
      ㉓ - Waiting coins 리스트는 코인익절 후 정리 버튼으로 익절 후 더이상 포지션을 추가로 잡지 않는 코인 리스트입니다.(코인명을 클릭하면 코인선택 기능을 제공합니다.){"\n"}{"\n"}{"\n"}
      <h2 style={styles.highlight_pink}>******* 시드별 자동 매매 셋팅 하기 기능 *******</h2>
      - 안정적인 모드로서 저희가 추천하는 셋팅값입니다. 하지만 시장 상황에 따라 언제나 손실의 위험은 있을 수 있으므로 본인의 판단에 따라 활용하시기 바랍니다.{"\n"}{"\n"}
      <h2 style={styles.highlight_red}>절대주의사항</h2>
      * 자동매매프로그램은 손매매를 도와주는 역활을 하는 것뿐이지 <span style={styles.highlight_yellow}>절대적이지 않습니다.</span>{"\n"}{"\n"}
      * <span style={styles.highlight_yellow}>프로그램이 익숙해질때까진 되도록 진입간격은 기본셋팅 값을 사용하길 추천드립니다.</span> 큰 수익을 목표로 Inner line 또는 Outer line의 진입간격을 너무 작게 설정하지 마세요.(라인이 하나씩 생길때마다 직전 라인에서 증가율만큼 증가한 금액이 주문량으로 들어가기 때문에 점점 진입금액이 늘어납니다. - 기본적으로 100%로 설정되어 있습니다. 그 이유는 직전 금액에서 2배씩 들어가야 익절라인이 효율적으로 따라 붙기 때문입니다.){"\n"}{"\n"}
      * <span style={styles.highlight_yellow}>Inner line의 진입간격과 진입횟수에 따라 Outer line과 겹치는 현상이 발생할 수 있습니다.</span>{"\n"}
      (예시 - Inner line 집입간격 1%, 진입횟수 4 - 첫 진입 포지션으로 부터 1%,2%,3%,4%에 한개씩 총 4개의 라인 생성(4%까지 생성) / Outer line 진입간격 4%, 진입횟수 3 - 첫 진입 포지션으로 부터 4%,8%,12%에 한개씩 처음 3개의 라인 생성 - 이렇게 셋팅한다면 4%에 두개의 라인이 겹쳐서 생성되는 현상 발생합니다.<span style={styles.highlight_yellow}> (Inner line의 진입간격 X 진입횟수 보다 Outer line의 진입간격이 더 크게 되도록 설정하세요))</span>
      {"\n"}{"\n"}
  </pre>
<button className='confirm-button' onClick={handleClose}>확인</button>
</div>
);
};

const styles = {
  container: {
    backgroundColor: 'black',
    color: 'white',
    padding: '50px 50px 50px 50px', // 상, 우, 하, 좌 순서
    fontFamily: 'Arial, sans-serif',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start', // 수평 가운데 정렬
  },
  title: {
    color: 'white',
  },
  text: {
    whiteSpace: 'pre-wrap', // 유지하려면 주석처리
    wordWrap: 'break-word', // 유지하려면 주석처리
    fontSize: '14px', // 글자 크기
    lineHeight: '1.6', // 줄 간격
  },
  image: {
    display: 'block',
    margin: '20px 0',
    maxWidth: '100%',
    height: 'auto',
  },
  highlight_pink: {
    color: 'pink', // 강조하고 싶은 텍스트 색상
  },
  highlight_yellow: {
    color: 'yellow', // 강조하고 싶은 텍스트 색상
  },
  highlight_red: {
    color: 'red', // 강조하고 싶은 텍스트 색상
  },
};
export default Explanation;
