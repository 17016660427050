import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './Main';
import SetPage from './SetPage';
import SelectPage from './SelectPage';
import SetPage_hook from './SetPage_hook';
import Signup from './Signup';
import Login from './Login';
import AdminPage from './adminpage';
import AdminLogin from './admin_login';
import CheckboxPage from './CheckboxPage';
import Withdrawal from './withdrawal';
import HomeTerms from './home-terms';
import PrivacyTerms from './privacy-terms';
import Explanation from './explanation';
import Another_AdminPage from './another_adminpage';
import Another_AdminLogin from './anonther_admin_login';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/Signup_ats" element={<Signup />} />
        <Route path="/Login_ats" element={<Login />} />
        <Route path="/SetPage" element={<SetPage />} />
        <Route path="/SelectPage" element={<SelectPage />} />
        <Route path="/SetPage_hook" element={<SetPage_hook />} />
        <Route path="/adminpage" element={<AdminPage />} />
        <Route path="/AdminLogin" element={<AdminLogin />} />
        <Route path="/CheckboxPage" element={<CheckboxPage />} />
        <Route path="/withdrawal" element={<Withdrawal />} />
        <Route path="/home-terms" element={<HomeTerms />} />
        <Route path="/privacy-terms" element={<PrivacyTerms />} />
        <Route path="/Explanation" element={<Explanation />} />
        <Route path="/Another_adminpage" element={<Another_AdminPage />} />
        <Route path="/Another_admin_login" element={<Another_AdminLogin />} />
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById('root')); // ReactDOM을 사용하여 App 컴포넌트 렌더링

export default App;
