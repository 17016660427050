import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';

export default function Another_AdminPage() {

  const [cookies] = useCookies(['uuid']);

  // 쿠키에서 UUID를 이용하여 사용자 정보 가져오기
  const[id,setID] = useState('');
  const[pw,setPW] = useState('');

  const [users, setUsers] = useState([]);
  const [returnRates, setReturnRates] = useState({});
  const [passUsdt, setPassUsdt] = useState({});
  const [currentUsdt, setCurrentUsdt] = useState({});

  useEffect(() => {
    if (!cookies.uuid) {
      alert('로그인 정보가 존재하지 않습니다. 관리자 로그인 페이지로 이동합니다.');
      window.location.href = `/Another_admin_login`;
      return;
    } else {
      const uuid = cookies.uuid;
      fetchUserInfo(uuid);
    }
  }, []);

  useEffect(() => {
    console.log('관리자 uuid', cookies.uuid);
  }, [cookies.uuid]);

  const fetchUserInfo = (uuid) => {
    fetch(`/SetPage/admin_user_another?uuid=${uuid}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('서버 응답 실패');
        }
        return response.json();
      })
      .then(data => {
        const user = data[0];
        setID(user.id);
        setPW(user.pw);
      })
      .catch(error => {
        alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
        window.location.href = `/Another_admin_login`;
        return;
      });
  };


  const handlePassUsdtChange = (uid, value) => {
    setPassUsdt(prevState => ({
      ...prevState,
      [uid]: value
    }));
  };

  const handleCurrentUsdtChange = (uid, value) => {
    setCurrentUsdt(prevState => ({
      ...prevState,
      [uid]: value
    }));
  };


  const handleSave = async (uid,nickname) => {
    const confirmed = window.confirm(`UID : ${uid} / NICKNAME : ${nickname} 님의 입력한 USDT 정보가 맞습니까?`);

    if (confirmed) {

    try {
      const response = await fetch('/admin/input', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid, passUsdt: passUsdt[uid], currentUsdt: currentUsdt[uid] }),
      });
      if (!response.ok) {
        throw new Error('Failed to save user data');
      }
      const data = await response.json();
      console.log(data);
      getReturnRate(uid, passUsdt[uid], currentUsdt[uid])
      alert(`UID : ${uid} / NICKNAME : ${nickname} 님의 USDT 정보가 저장 되었습니다.`);
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };
};

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`/admin/another_users`);
        if (!response.ok) {
          throw new Error('서버 응답 실패');
        }
        const data = await response.json();
        setUsers(data);
        const initialPassUsdt = {};
        const initialCurrentUsdt = {};
        const initialReturnRates = {};
        data.forEach(user => {
          const passUsdtValue = parseFloat(user.pass_usdt) || 0;
          const currentUsdtValue = parseFloat(user.current_usdt) || 0;
          initialPassUsdt[user.uid] = passUsdtValue;
          initialCurrentUsdt[user.uid] = currentUsdtValue;
          initialReturnRates[user.uid] = (currentUsdtValue !== 0 && passUsdtValue !== 0) ? (currentUsdtValue - passUsdtValue) * 0.2 : '';
        });
        setPassUsdt(initialPassUsdt);
        setCurrentUsdt(initialCurrentUsdt);
        setReturnRates(initialReturnRates);
      } catch (error) {
        console.error('Waiting List 가져오기 오류:', error);
        alert(error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    console.log('Users 상태값이 변경되었습니다:', users);
  }, [users]);


  const getReturnRate = (uid, pass_usdt, current_usdt) => {
    const passUsdtFloat = parseFloat(pass_usdt);
    const currentUsdtFloat = parseFloat(current_usdt);

    let rate = '';
    if ((currentUsdtFloat !== 0 && passUsdtFloat !== 0)) {
      rate = ((currentUsdtFloat - passUsdtFloat) * 0.2).toFixed(2);
      setReturnRates(prevState => ({
        ...prevState,
        [uid]: rate
      }));
    }
  };

  const getApprovalStyle = (loginPossible) => {
    let style = {
      color: loginPossible === "1" || loginPossible === 1 ? "red" : "black",
    };
    return style;
  };

  const getApprovalText = (loginPossible) => {
    let text = loginPossible === "1" || loginPossible === 1 ? "승인" : "승인대기중";
    return text;
  };

  //로그아웃
const handleLogout = () => {
  const uuid = cookies.uuid; // cookies에서 uuid를 얻어옴
  if (!uuid) {
    alert('로그인 정보가 존재하지 않습니다.');
    return;
  }
  // 로그아웃 요청을 서버로 보냅니다.
  fetch('/admin_logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userId: uuid })
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('로그아웃 요청 실패');
    }
    console.log('로그아웃 성공');
    alert('Logout is successful.');
    window.location.href = '/Another_admin_login';
  })
  .catch(error => {
    console.error('로그아웃 요청 중 오류 발생:', error);
  });
// UUID를 서버로 보내는 예시 코드

}

  return (
    <div className="container_admin">
      <div className="header">
        <h2 className="title_admin">User Information (관리자 페이지)</h2>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      <table className="user-table">
        <thead>
          <tr>
            <th>#</th>
            <th>UID</th>
            <th>E-mail</th>
            <th>Mobile</th>
            <th>Nickname</th>
            <th>초기자본</th>
            <th>현재자본</th>
            <th>저장하기</th>
            <th>수익률의20%</th>
            <th>승인여부</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.uid}>
              <td>{index + 1}</td>
              <td>{user.uid}</td>
              <td>{user.email}</td>
              <td>{user.mobile}</td>
              <td>{user.nickname}</td>
              <td>
                <input 
                  type="number" 
                  className="styled-input" 
                  value={passUsdt[user.uid] || ''} 
                  onChange={(e) => handlePassUsdtChange(user.uid, e.target.value)} 
                />
              </td>
              <td>
                <input 
                  type="number" 
                  className="styled-input" 
                  value={currentUsdt[user.uid] || ''} 
                  onChange={(e) => handleCurrentUsdtChange(user.uid, e.target.value)} 
                />
              </td>
              <td>
                <button className="action-button large-button" onClick={() => handleSave(user.uid,user.nickname)}>저장</button>
              </td>
              <td>{returnRates[user.uid] || ''}</td>
              <td style={getApprovalStyle(user.login_possible)}>{getApprovalText(user.login_possible)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
